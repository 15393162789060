








































import Vue from 'vue';
import { Customer } from '@/types';
import { emitCustomerChanged } from '@/events';
import { EventBus, events } from '@/events';

export default Vue.extend({
  name: 'App',
  data: function () {
    return {
      customer: null,
      customers: Array<Customer>(),
      isLoggedIn: false,
      snackbar: {
        open: false,
        text: "",
        success: true,
        timeout: 3000
      }
    }
  },
  mounted () {
    const storedCustomer = localStorage.getItem('customer');

    if (storedCustomer) {
      this.customer = JSON.parse(storedCustomer);
    }

    this.loadCustomers();

    if (localStorage.backendToken != null) {
      this.isLoggedIn = true;
    } else if (this.$route.name != 'login') {
      // If the user is not logged in redirect
      // him to the login screen
      this.$router.push({name: 'login'})
    }

    // Listen for loggedIn events. When an user logs in
    // show the sidebar
    EventBus.$on(events.loggedIn, () => {
      this.$store.commit('refreshApiClient');
      this.isLoggedIn = true;

      // Show logged-in snackbar
      this.snackbar.open = true;
      this.snackbar.text = 'Logged in';

      this.loadCustomers();
    })
  },
  methods: {
    loadCustomers () {
      this.$store.state.api.getCustomers().then((customers: Array<Customer>) => {
        this.customers = customers;
      });
    }
  },
  watch: {
    customer (newCustomer) {
      localStorage.customer = JSON.stringify(newCustomer);
      emitCustomerChanged(newCustomer);
    }
  }
});

