import Vue from 'vue';
import { Customer } from '@/types';

export const EventBus = new Vue();
export const events = {
    customerChanged: "customerChanged",
    loggedIn: "loggedIn"
}

function emitCustomerChanged(customer: Customer) {
    EventBus.$emit(events.customerChanged, customer);
}

function emitLoggedIn() {
    EventBus.$emit(events.loggedIn);
}

export {
    emitCustomerChanged,
    emitLoggedIn
};