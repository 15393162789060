





























import Vue from 'vue';

export default Vue.extend({
    name: 'FieldsTable',
    props: {
        dataObject: Object,
        diff: Object
    }
});
