import Vue from 'vue'
import Vuex from 'vuex'
import { TimeMachineApi } from '@/api'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: new TimeMachineApi(),
  },
  getters: {

  },
  mutations: {
    refreshApiClient (state) {
      state.api = new TimeMachineApi();
    }
  },
  actions: {

  },
  modules: {

  }
});
