

















































import Vue, { PropType } from 'vue';
import { GoogleAdsEntity } from '@/types';
import FieldsTable from '@/components/FieldsTable.vue'

export default Vue.extend({
    name: 'EntitiesPanel',
    components: {
        FieldsTable
    },
    props: {
        entities: Array as PropType<Array<GoogleAdsEntity>>
    }
});

