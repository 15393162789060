






































































import Vue from 'vue';

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators'

export default Vue.extend({
  name: 'LoginView',
  components: {},
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required }
  },
  data: function() {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login() {
      // Validate form
      this.$v.$touch();
      if (this.$v.$invalid) return;

      // FIXME: Handle login errors
      this.$store.state.api.login(this.email, this.password).then(() => {
        this.$router.push({name: "home"});
      });
    }
  },
  computed: {
    emailErrors() {
      const errors = Array<string>();

      if (!this.$v.email.$dirty) return errors;

      !this.$v.email.required && errors.push('An email is required');
      !this.$v.email.email && errors.push('Invalid email');

      return errors;
    },
    passwordErrors() {
      const errors = Array<string>();

      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('A password is required');

      return errors;
    }
  }
})
