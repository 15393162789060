

























































































import Vue from 'vue';
import { Snapshot, Campaign, Customer } from '@/types';
import EntitiesPanel from '@/components/EntitiesPanel.vue';
import { EventBus, events } from '@/events';


export default Vue.extend({
  name: 'CompareSnapshots',
  components: {
    EntitiesPanel
  },
  data: function () {
    return {
      snapshots: new Array<Snapshot>(),
      firstSnapshotId: -1,
      secondSnapshotId: -1,
      diffCampaigns: new Array<Campaign>(),
      snackbar: {
        open: false,
        text: '',
        timeout: 3000
      },
      spinner: {
        active: false
      },
      customer: {customer_id: null, name: ""} as Customer
    }
  },
  mounted: function () {
    this.loadCustomer();

    if (this.customer.customer_id != null) this.refreshSnapshots();
    EventBus.$on(events.customerChanged, this.newCustomerCallback);
  },
  methods: {
    compareSnapshots() {
      if (this.firstSnapshotId <= 0 || this.secondSnapshotId <= 0) {
        this.snackbar.text = 'Select two snapshots first!';
        this.snackbar.open = true;

        return;
      }

      this.spinner.active = true;

      this.$store.state.api.compareSnapshots(
        this.firstSnapshotId,
        this.secondSnapshotId
      ).then((campaigns: Array<Campaign>) => {
          this.diffCampaigns = campaigns;
          this.spinner.active = false;
        }
      ).catch((error: Error) => {
        this.spinner.active = false;

        this.snackbar.text = 'There was an error comparing snapshots. Please retry';
        this.snackbar.open = true;

        console.log(`Error comparing snapshots (${this.firstSnapshotId}, ${this.secondSnapshotId}): ${error}`);
      });
    },
    loadCustomer() {
      // FIXME: Organize local storage keys in an object
      const storedCustomer = localStorage.getItem("customer");

      if (storedCustomer) {
        this.customer = JSON.parse(storedCustomer);
      }
    },
    refreshSnapshots() {
      this.$store.state.api.getSnapshots(this.customer.customer_id).then((snapshots: Array<Snapshot>) => {
        this.snapshots = snapshots;
      });
    },
    newCustomerCallback(newCustomer: Customer) {
      this.customer = newCustomer;
      this.snapshots = [];
      this.diffCampaigns = [];

      this.refreshSnapshots();
    }
  }
})
