import * as moment from "moment";

import { Snapshot } from "@/types";
import axios, { AxiosInstance, AxiosError, AxiosResponse } from "axios";
import { emitLoggedIn } from "@/events";

class TimeMachineApi {
    private client: AxiosInstance;

    constructor() {
        this.client = axios.create({
            baseURL: process.env.VUE_APP_API_ROOT,
            headers: { Authorization: `Bearer ${localStorage.backendToken}` }
        });
    }

    login(email: string, password: string) {
        const payload = {
            email: email,
            password: password
        };

        return this.client.post("users/login", payload).then((response: AxiosResponse) => {
            localStorage.backendToken = response.data.token;
            emitLoggedIn();
            
            return response.data.token;
        });
    }

    getCustomers() {
        return this.client.get("customers/").then((response: AxiosResponse) => {
            return response.data.customers;
        }).catch((error: Error | AxiosError) => {
            console.log(`Could not fetch customers: ${error}`);
        });
    }

    getSnapshots(customerId: string) {
        return this.client.get(`snapshots/${customerId}`).then((response: AxiosResponse) => {
            const snapshots = response.data.snapshots;

            for (let i = 0; i < snapshots.length; i++) {
                snapshots[i].label = `${snapshots[i].id} - ${this.formatSnapshotDate(snapshots[i])}`
            }

            return snapshots;
        });
    }

    compareSnapshots(firstSnapshotId: number, secondSnapshotId: number) {
        const url = `snapshots/compare/${firstSnapshotId}/${secondSnapshotId}`;

        return this.client.get(url).then((response) => {
            return response.data.campaigns;
        });
    }

    formatSnapshotDate(snapshot: Snapshot) {
        return moment.unix(snapshot.created_at).format("YYYY/MM/DD hh:mm:ss");
    }
}

export { TimeMachineApi };